import { RootState } from '@/store';
import { getBaseStore } from '@/store/base';
import { merge } from 'lodash';
import { ActionContext } from 'vuex';

export interface ScheduledMeetingTagState {
    scheduledMeetingTag: ScheduledMeetingTag | null;
    scheduledMeetingTags: ScheduledMeetingTags | null;
    scheduledMeetingTagOptions: ScheduledMeetingTags | null;
}

export const scheduledMeetingTag = merge(getBaseStore('scheduledMeetingTag'), {
    actions: {
        async createScheduledMeetingTag({ commit, state }: ActionContext<ScheduledMeetingTagState, RootState>) {
            commit('updateScheduledMeetingTag', {
                label: '',
            });

            return state.scheduledMeetingTag;
        },
    },
});
