import i18n from '@/i18n';
import { RootState } from '@/store';
import { getBaseStore } from '@/store/base';
import { merge } from 'lodash';
import { ActionContext } from 'vuex';

export interface WidgetState {
    widget: Widget | null;
    widgets: Widgets | null;
    widgetOptions: Widgets | null;
}

export const widget = merge(getBaseStore('widget', 'live/'), {
    actions: {
        async createWidget({ commit, state }: ActionContext<WidgetState, RootState>) {
            commit('updateWidget', {
                name: '',
                groups: [],
                enabled_channels: [],
                title: i18n.global.t('widget.entity.titlePlaceholder'),
                introduction_text: i18n.global.t('widget.entity.introductionPlaceholder'),
                fallback_description: i18n.global.t('widget.entity.fallbackDescriptionPlaceholder'),
                channel_video_description: i18n.global.t('widget.entity.channelVideoDescription'),
                channel_messaging_description: i18n.global.t('widget.entity.channelMessagingDescription'),
                fallback_policy: 'hidden',
                fallback_messages: {
                    no_consultant_available: i18n.global.t('widget.entity.fallback_messages.no_consultant_availablePlaceholder'),
                    widget_unavailable: i18n.global.t('widget.entity.fallback_messages.widget_unavailablePlaceholder'),
                },
                welcome_message_conversation: i18n.global.t('widget.entity.welcome_message_conversationPlaceholder'),
                waiting_message_conversation: i18n.global.t('widget.entity.waiting_message_conversationPlaceholder'),
                waiting_message_video: i18n.global.t('widget.entity.waiting_message_videoPlaceholder'),
                end_message_video: i18n.global.t('widget.entity.end_message_videoPlaceholder'),
                end_message_conversation: i18n.global.t('widget.entity.end_message_conversationPlaceholder'),
            });

            return state.widget;
        },
    },
});
