import Axios from '@/axios';
import { GetItemPayload, GetItemsPayload } from '@/store';
import { getItemsAction } from '@/store/common';
import { getPlural } from '@/utils/getPlural';
import { snakeCase, upperFirst } from 'lodash';

export const getBaseStore = (item: string, prePath = '') => {
    const items = getPlural(item);
    const itemOptions = item + 'Options';
    const updateItem = 'update' + upperFirst(item);
    const deleteItem = 'delete' + upperFirst(item);
    const updateItems = 'update' + upperFirst(getPlural(item));
    const updateItemOptions = 'update' + upperFirst(item) + 'Options';
    const getItem = 'get' + upperFirst(item);
    const getItems = 'get' + upperFirst(getPlural(item));

    const updateStatusItem = 'updateStatus' + upperFirst(item);

    return {
        namespaced: true,
        state: () => ({
            [item]: null,
            [items]: null,
            [itemOptions]: null,
        }),
        mutations: {
            [updateItem]: (state, value) => {
                state[item] = value;
            },
            [updateItems]: (state, value) => {
                state[items] = value;
            },
            [updateItemOptions]: (state, value) => {
                state[itemOptions] = value;
            },
        },
        actions: {
            async [getItems](actionContext, payload: GetItemsPayload) {
                return getItemsAction(actionContext, payload, item, prePath);
            },
            async [getItem]({ commit }, { id, params, useCommit = true }: GetItemPayload) {
                const data = await Axios.request({
                    method: 'get',
                    url: '/api/private/' + prePath + snakeCase(getPlural(item)) + '/' + id,
                    params,
                });
                if (useCommit) commit(updateItem, data);
                return data;
            },
            async [updateStatusItem](context, { enabled, id }: { enabled: boolean; id: string }) {
                return await Axios.request({
                    method: 'put',
                    url: '/api/private/' + prePath + snakeCase(getPlural(item)) + '/' + id,
                    data: {
                        enabled,
                    },
                });
            },
            async [deleteItem](context, id: string) {
                return await Axios.request({
                    method: 'delete',
                    url: '/api/private/' + prePath + snakeCase(getPlural(item)) + '/' + id,
                });
            },
        },
        getters: {
            [items](state) {
                return state[items] ? state[items]['hydra:member'] : [];
            },
            [itemOptions](state) {
                return state[itemOptions] ? state[itemOptions]['hydra:member'] : [];
            },
        },
    };
};
