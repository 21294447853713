<template>
    <component
        class="tw-relative tw-flex tw-h-10 tw-w-auto tw-items-center tw-justify-center tw-rounded-md tw-border tw-px-4 tw-font-semibold hover:tw-opacity-70"
        :is="tag"
        :type="tag === 'button' ? type : null"
        :disabled="loading ? true : null"
        :class="{
            'is-loading tw-pointer-events-none': loading,
            ['tw-bg-' + color]: color && !outline,
            ['tw-border-' + color]: color,
            'tw-border-solid': color && outline,
            ['tw-text-' + color]: color && outline,
            'tw-text-white': (color !== 'white' && !outline) || (color === 'white' && outline),
            'tw-text-body': color === 'white' && !outline,
        }"
    >
        <span class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center" v-if="loading">
            <ion-spinner :color="spinnerColor" name="dots" />
        </span>
        <span class="tw-flex tw-grow tw-items-center tw-justify-center tw-text-sm tw-leading-none" :class="{ 'tw-opacity-0': loading }">
            <slot />
        </span>
    </component>
</template>

<script setup lang="ts">
    import { IonSpinner } from '@ionic/vue';
    import { computed, toRefs } from 'vue';

    const props = withDefaults(
        defineProps<{
            tag?: 'button' | 'a';
            loading?: boolean;
            type?: 'submit' | 'button';
            color?: 'primary' | 'secondary' | 'tertiary' | 'white' | 'black' | 'body' | 'red-500' | 'green-500';
            outline?: boolean;
        }>(),
        {
            tag: 'button',
            type: 'submit',
        },
    );
    const { color, outline } = toRefs(props);

    const spinnerColor = computed(() => {
        if (
            !outline.value &&
            (color.value === 'primary' ||
                color.value === 'secondary' ||
                color.value === 'tertiary' ||
                color.value === 'black' ||
                color.value === 'body')
        ) {
            return 'white';
        } else if (!outline.value && color.value === 'white') {
            return 'black';
        } else if (outline.value && color.value !== 'white') {
            return color.value;
        } else {
            return 'black';
        }
    });
</script>
