import { getBaseStore } from '@/store/base';
import { merge } from 'lodash';

export interface CrmFieldsState {
    areaCrmField: AreaCrmField | null;
    areaCrmFields: AreaCrmFields | null;
    areaCrmFieldOptions: AreaCrmFields | null;
}

export const areaCrmField = merge(getBaseStore('areaCrmField'));
