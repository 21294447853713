<template>
    <ion-content>
        <div class="d-flex flex-column h-100 px-5 pt-5">
            <div class="flex-grow-1 d-flex align-items-center">
                <div class="mb-2 text-center">
                    <IonIcon class="ring-title-icon" src="assets/icons/bell.svg"></IonIcon>
                    <p class="title1 mt-3">{{ $t(`notification.modal.title`) }}</p>
                    <div class="wysiwyg mt-4">
                        <p>{{ $t(`notification.modal.body`) }}</p>
                        <p class="fs-2 text-gray mt-5">
                            <i>{{ $t(`notification.modal.body2`) }}</i>
                        </p>
                    </div>
                </div>
            </div>
            <form class="actions justify-content-between">
                <ApendayButton color="primary" outline @click.prevent="skip">
                    {{ $t('common.skip') }}
                </ApendayButton>
                <ApendayButton class="btn-full" type="submit" @click.prevent="askNotifsPermissions">
                    {{ $t('notification.modal.authorize') }}
                </ApendayButton>
            </form>
        </div>
    </ion-content>
</template>

<script lang="ts">
    import ApendayButton from '@/components/ApendayButton.vue';
    import { closeNotificationModal, registerNotifications } from '@/composables/push-notifications';
    import i18n from '@/i18n';
    import router from '@/router';
    import { storageSet } from '@/store/modules/user';
    import { Capacitor } from '@capacitor/core';
    import { alertController, IonContent, IonIcon } from '@ionic/vue';
    import { computed, defineComponent } from 'vue';
    import { useRoute } from 'vue-router';
    import { useStore } from 'vuex';

    export default defineComponent({
        components: {
            IonContent,
            IonIcon,
            ApendayButton,
        },
        setup() {
            const route = useRoute();
            const store = useStore();
            const me = computed(() => store.state.user.me);
            const skip = async () => {
                await storageSet('push_notifications_asked', true);
                closeNotificationModal();
            };
            const platform = Capacitor.getPlatform();

            const askNotifsPermissions = async () => {
                await storageSet('push_notifications_asked', true);

                try {
                    await registerNotifications();
                    closeNotificationModal();
                    const buttons = [
                        {
                            text: i18n.global.t('alerts.actions.next'),
                            role: 'cancel',
                        },
                    ];
                    if (route.name !== 'user-edit-notifications')
                        buttons.push({
                            text: i18n.global.t('alerts.notifications.success.userButton'),
                            role: 'confirm',
                        });
                    const alert = await alertController.create({
                        header: i18n.global.t(`alerts.notifications.success.header`),
                        message: i18n.global.t(`alerts.notifications.success.message`),
                        buttons,
                    });

                    await alert.present();
                    const { role } = await alert.onDidDismiss();
                    if (role === 'confirm')
                        router.push({
                            name: 'user-edit-notifications',
                            params: { id: me.value.id },
                        });
                } catch (e) {
                    closeNotificationModal();
                    const alert = await alertController.create({
                        header: i18n.global.t(`alerts.notifications.error.${platform}.header`),
                        message: i18n.global.t(`alerts.notifications.error.${platform}.message`),
                        cssClass: 'wysiwyg-alert',
                        buttons: [
                            {
                                text: i18n.global.t('alerts.actions.understood'),
                                role: 'confirm',
                                cssClass: 'confirm',
                            },
                        ],
                    });

                    alert.present();
                }
            };

            return {
                skip,
                askNotifsPermissions,
                platform,
            };
        },
    });
</script>
