import { RootState } from '@/store';
import { getBaseStore } from '@/store/base';
import { merge } from 'lodash';
import { ActionContext } from 'vuex';

export interface WidgetAvailabilityState {
    widgetAvailability: WidgetAvailability | null;
    widgetAvailabilities: WidgetAvailabilities | null;
    widgetAvailabilityOptions: WidgetAvailabilities | null;
}

export const widgetAvailability = merge(getBaseStore('widgetAvailability', 'live/'), {
    actions: {
        async createWidget({ commit, state }: ActionContext<WidgetAvailabilityState, RootState>) {
            commit('updateWidgetAvailability', {
                name: '',
            });

            return state.widgetAvailability;
        },
    },
});
