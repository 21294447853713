import { toast } from '@/composables/toast';
import i18n from '@/i18n';
import Store, { GetItemPayload } from '@/store';
import { alertController } from '@ionic/vue';
import { isEmpty, kebabCase, upperFirst } from 'lodash';
import { RouteLocationNormalized, RouteLocationRaw } from 'vue-router';

export const showEntityNotFoundAlert = async () => {
    const alert = await alertController.create({
        header: i18n.global.t('alerts.notFound.header'),
        message: i18n.global.t('alerts.notFound.message'),
        cssClass: 'wysiwyg-alert',
        buttons: [
            {
                text: i18n.global.t('alerts.actions.understood'),
                role: 'confirm',
                cssClass: 'confirm',
            },
        ],
    });

    await alert.present();
};

export interface RouteGuardContext {
    action?: 'edit' | 'create' | 'edit-create';
    status?: 'success' | 'error';
}

export const editBeforeRouteEnter = async (
    routeLocation: RouteLocationNormalized,
    {
        entityName,
        promises = [],
        payload = {},
        failRouteName,
        callback = (route) => route,
        routerIdName = 'id',
    }: {
        entityName: EntityType;
        failRouteName?: string;
        promises?: Promise<any>[];
        payload?: GetItemPayload;
        routerIdName?: string;
        callback?: (route: RouteLocationRaw, item, context: RouteGuardContext) => RouteLocationRaw;
    },
) => {
    entityName = entityName ?? routeLocation.meta.entityName;
    try {
        if (routeLocation.params[routerIdName]) {
            // Edit
            promises.unshift(
                Store.dispatch(`${entityName}/get${upperFirst(entityName)}`, {
                    id: routeLocation.params[routerIdName],
                    ...payload,
                }),
            );
            try {
                const responses = await Promise.all(promises);
                return await callback(null, responses[0], {
                    action: 'edit',
                    status: 'success',
                });
            } catch (e) {
                if (e?.response?.status === 404) {
                    showEntityNotFoundAlert();
                } else {
                    toast({
                        text: isEmpty(e.customErrorMsg) ? i18n.global.t('common.errorServer') : e.customErrorMsg,
                        type: 'error',
                    });
                }
                return await callback({ name: failRouteName ?? `${kebabCase(entityName)}-list` }, null, {
                    action: 'edit',
                    status: 'error',
                });
            }
        } else {
            // Create
            await Promise.all(promises);
            const item = await Store.dispatch(`${entityName}/create${upperFirst(entityName)}`, payload);
            return await callback(null, item, {
                action: 'create',
                status: 'success',
            });
        }
    } catch (e) {
        toast({
            text: e.customErrorMsg ?? e.toString(),
            type: 'error',
        });
        return await callback(null, null, {
            action: 'edit-create',
            status: 'error',
        });
    }
};
