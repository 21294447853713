import { RootState } from '@/store';
import { getBaseStore } from '@/store/base';
import { merge } from 'lodash';
import { ActionContext } from 'vuex';

export interface AgencyState {
    agency: Agency | null;
    agencies: Agencies | null;
    agencyOptions: Agencies | null;
}

export const agency = merge(getBaseStore('agency'), {
    actions: {
        async createAgency({ commit, state }: ActionContext<AgencyState, RootState>) {
            commit('updateAgency', {
                name: '',
            });
            return state.agency;
        },
    },
});
