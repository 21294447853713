import loader from '@/composables/loader';
import i18n from '@/i18n';
import { alertController } from '@ionic/vue';

export const onImportError = async () => {
    loader.finish();
    const alert = await alertController.create({
        header: i18n.global.t('alerts.app.reload.header'),
        message: i18n.global.t('alerts.app.reload.message'),
        buttons: [
            {
                text: i18n.global.t('alerts.actions.understood'),
                role: 'confirm',
            },
        ],
    });
    alert.present();
    await alert.onDidDismiss();
    window.location.reload();
};
