import { RootState } from '@/store';
import { getBaseStore } from '@/store/base';
import { merge } from 'lodash';
import { ActionContext } from 'vuex';

export interface SmtpSettingState {
    smtpSetting: SmtpSetting | null;
    smtpSettings: SmtpSettings | null;
    smtpSettingOptions: SmtpSettings | null;
}

export const smtpSetting = merge(getBaseStore('smtpSetting'), {
    actions: {
        async createSmtpSetting({ commit, state }: ActionContext<SmtpSettingState, RootState>) {
            commit('updateSmtpSetting', {
                label: '',
            });

            return state.smtpSetting;
        },
    },
});
