import { RootState } from '@/store';
import { getBaseStore } from '@/store/base';
import { merge } from 'lodash';
import { ActionContext } from 'vuex';

export interface SupportMessageState {
    supportMessage: SupportMessage | null;
    supportMessages: SupportMessages | null;
    supportMessageOptions: SupportMessages | null;
}

export const supportMessage = merge(getBaseStore('supportMessage'), {
    actions: {
        async createSupportMessage({ commit }: ActionContext<SupportMessageState, RootState>) {
            commit('updateSupportMessage', {
                content: '',
                attachments: [],
            });
        },
    },
});
