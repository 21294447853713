import { getBaseStore } from '@/store/base';
import { merge } from 'lodash';

export interface AreaCrmMeetingState {
    areaCrmMeeting: AreaCrmMeeting | null;
    areaCrmMeetings: AreaCrmMeetings | null;
    areaCrmMeetingOptions: AreaCrmMeetings | null;
}

export const areaCrmMeeting = merge(getBaseStore('areaCrmMeeting'));
