import Axios from '@/axios';
import { getBaseStore } from '@/store/base';
import { merge } from 'lodash';

export interface AppEventState {
    appEvent: AppEvent | null;
    appEvents: AppEvents | null;
    unreadAppEvents: AppEvents | null;
    appEventOptions: AppEvents | null;
}

export const appEvent = merge(getBaseStore('appEvent'), {
    state: () => ({
        unreadAppEvents: null,
        isFetching: false,
    }),
    mutations: {
        updateUnreadAppEvents: (state, value) => {
            state['unreadAppEvents'] = value;
        },
        updateIsFetching: (state, value) => {
            state['isFetching'] = value;
        },
    },
    actions: {
        async getUnreadAppEvents({ commit }) {
            commit('updateIsFetching', true);
            const data = await Axios.request({
                method: 'get',
                url: '/api/private/app_events/unread',
                params: {
                    pagination: false,
                    'extra[permissions]': 0,
                    'order[created_at]': 'desc',
                },
            });
            commit('updateUnreadAppEvents', data);
            commit('updateIsFetching', false);
        },
        async markAllAsRead({ commit }) {
            commit('updateIsFetching', true);
            await Axios.request({
                method: 'options',
                url: '/api/private/app_events/mark-all-as-read',
            });
            commit('updateIsFetching', false);
        },
        async markAsRead(state, { item }) {
            const id = item['@id'].split('/').pop();
            await Axios.request({
                method: 'options',
                url: `/api/private/app_events/${id}/mark-as-read`,
            });
        },
    },
    getters: {
        unreadCount(state): number {
            return state['unreadAppEvents'] ? state['unreadAppEvents']['hydra:totalItems'] : 0;
        },
        unreadAppEvents(state) {
            return state['unreadAppEvents'] ? state['unreadAppEvents']['hydra:member'] : [];
        },
    },
});
