import { GetItemsPayload } from '@/store';
import { getBaseStore } from '@/store/base';
import { getItemsAction } from '@/store/common';
import { isObject, merge } from 'lodash';

export interface AvailabilityState {
    availabilities: Availabilities | null;
    recurrentAvailabilities: Availabilities | null;
    punctualAvailabilities: Availabilities | null;
}

export const availability = merge(getBaseStore('availability'), {
    namespaced: true,
    state: (): AvailabilityState => ({
        availabilities: null,
        recurrentAvailabilities: null,
        punctualAvailabilities: null,
    }),
    mutations: {
        updateAvailabilities: (state: AvailabilityState, value: Availabilities | null) => {
            state.availabilities = value;
        },
        updateRecurrentAvailabilities: (state: AvailabilityState, value: Availabilities | null) => {
            state.recurrentAvailabilities = value;
        },
        updatePunctualAvailabilities: (state: AvailabilityState, value: Availabilities | null) => {
            state.punctualAvailabilities = value;
        },
    },
    actions: {
        async getAvailabilities(actionContext, payload: GetItemsPayload) {
            return getItemsAction(actionContext, payload, 'availability');
        },
        async getRecurrentAvailabilities(actionContext, payload: GetItemsPayload) {
            if (!isObject(payload.params)) payload.params = {};
            payload.params.type = 'recurrent';
            return getItemsAction(
                actionContext,
                {
                    ...payload,
                    prefixName: 'recurrent',
                },
                'availability',
            );
        },
        async getPunctualAvailabilities(actionContext, payload: GetItemsPayload) {
            if (!isObject(payload.params)) payload.params = {};
            payload.params.type = 'punctual';
            return getItemsAction(
                actionContext,
                {
                    ...payload,
                    prefixName: 'punctual',
                },
                'availability',
            );
        },
    },
    getters: {
        availabilities(state: AvailabilityState): Availability[] {
            return state.availabilities ? state.availabilities['hydra:member'] : [];
        },
        recurrentAvailabilities(state: AvailabilityState): Availability[] {
            return state.recurrentAvailabilities ? state.recurrentAvailabilities['hydra:member'] : [];
        },
        punctualAvailabilities(state: AvailabilityState): Availability[] {
            return state.punctualAvailabilities ? state.punctualAvailabilities['hydra:member'] : [];
        },
    },
});
