import { getBaseStore } from '@/store/base';
import { merge } from 'lodash';

export interface UserDefaultAvailabilityState {
    userDefaultAvailability: UserDefaultAvailability | null;
    userDefaultAvailabilities: UserDefaultAvailabilities | null;
    userDefaultAvailabilityOptions: UserDefaultAvailabilities | null;
}

export const userDefaultAvailability = merge(getBaseStore('userDefaultAvailability'));
