import Axios from '@/axios';
import { GetItemsPayload, RootState } from '@/store';
import { getBaseStore } from '@/store/base';
import { getItemsAction } from '@/store/common';
import { merge } from 'lodash';
import { ActionContext } from 'vuex';

export interface ScheduledMeetingState {
    scheduledMeetings: ScheduledMeetings | null;
    forthcomingScheduledMeetings: ScheduledMeetings | null;
    pastScheduledMeetings: ScheduledMeetings | null;
    historyScheduledMeetings: ScheduledMeetings | null;
    scheduledMeeting?: ScheduledMeeting | null;
    utmSourceScheduledMeetingsOptions?: ScheduledMeetingFilters | null;
    utmMediumScheduledMeetingsOptions?: ScheduledMeetingFilters | null;
    utmCampaignScheduledMeetingsOptions?: ScheduledMeetingFilters | null;
}

export const scheduledMeeting = merge(getBaseStore('scheduledMeeting'), {
    namespaced: true,
    state: (): ScheduledMeetingState => ({
        scheduledMeetings: null,
        forthcomingScheduledMeetings: null,
        pastScheduledMeetings: null,
        historyScheduledMeetings: null,
        utmSourceScheduledMeetingsOptions: null,
        utmMediumScheduledMeetingsOptions: null,
        utmCampaignScheduledMeetingsOptions: null,
    }),
    mutations: {
        updateScheduledMeetings: (state: ScheduledMeetingState, value: ScheduledMeetings | null) => {
            state.scheduledMeetings = value;
        },
        updateForthcomingScheduledMeetings: (state: ScheduledMeetingState, value: ScheduledMeetings | null) => {
            state.forthcomingScheduledMeetings = value;
        },
        updatePastScheduledMeetings: (state: ScheduledMeetingState, value: ScheduledMeetings | null) => {
            state.pastScheduledMeetings = value;
        },
        updateHistoryScheduledMeetings: (state: ScheduledMeetingState, value: ScheduledMeetings | null) => {
            state.historyScheduledMeetings = value;
        },
        updateUtmSourceScheduledMeetingOptions: (state: ScheduledMeetingState, value: ScheduledMeetingFilters | null) => {
            state.utmSourceScheduledMeetingsOptions = value;
        },
        updateUtmMediumScheduledMeetingOptions: (state: ScheduledMeetingState, value: ScheduledMeetingFilters | null) => {
            state.utmMediumScheduledMeetingsOptions = value;
        },
        updateUtmCampaignScheduledMeetingOptions: (state: ScheduledMeetingState, value: ScheduledMeetingFilters | null) => {
            state.utmCampaignScheduledMeetingsOptions = value;
        },
    },
    actions: {
        async createScheduledMeeting({ commit, state }: ActionContext<ScheduledMeetingState, RootState>) {
            commit('updateScheduledMeeting', {
                date: null,
            });

            return state.scheduledMeeting;
        },
        async getScheduledMeetings(actionContext, payload: GetItemsPayload) {
            return getItemsAction(actionContext, payload, 'scheduledMeeting');
        },
        async getForthcomingScheduledMeetings(actionContext, payload: GetItemsPayload) {
            return getItemsAction(actionContext, payload, 'scheduledMeeting');
        },
        async getPastScheduledMeetings(actionContext, payload: GetItemsPayload) {
            return getItemsAction(actionContext, payload, 'scheduledMeeting');
        },
        async getHistoryScheduledMeetings(actionContext, payload: GetItemsPayload) {
            return getItemsAction(actionContext, payload, 'scheduledMeeting');
        },
        async cancelScheduledMeeting(context: ActionContext<ScheduledMeetingState, RootState>, id: string) {
            return await Axios.request({
                method: 'put',
                url: '/api/private/scheduled_meetings/' + id + '/cancel',
                data: {},
            });
        },
        async missScheduledMeeting(
            context: ActionContext<ScheduledMeetingState, RootState>,
            { id, data = {} }: { id: string; data: object },
        ) {
            return await Axios.request({
                method: 'put',
                url: '/api/private/scheduled_meetings/' + id + '/miss',
                data,
            });
        },
        async reassignScheduledMeeting(context: ActionContext<ScheduledMeetingState, RootState>, id: string) {
            return await Axios.request({
                method: 'put',
                url: '/api/private/scheduled_meetings/' + id + '/reassign',
                data: {},
            });
        },
        async terminateScheduledMeeting(
            context: ActionContext<ScheduledMeetingState, RootState>,
            { id, data = {} }: { id: string; data: object },
        ) {
            return await Axios.request({
                method: 'put',
                url: '/api/private/scheduled_meetings/' + id + '/terminate',
                data,
            });
        },
    },
    getters: {
        scheduledMeetings(state: ScheduledMeetingState): ScheduledMeeting[] {
            return state.scheduledMeetings ? state.scheduledMeetings['hydra:member'] : [];
        },
        forthcomingScheduledMeetings(state: ScheduledMeetingState): ScheduledMeeting[] {
            return state.forthcomingScheduledMeetings ? state.forthcomingScheduledMeetings['hydra:member'] : [];
        },
        pastScheduledMeetings(state: ScheduledMeetingState): ScheduledMeeting[] {
            return state.pastScheduledMeetings ? state.pastScheduledMeetings['hydra:member'] : [];
        },
        historyScheduledMeetings(state: ScheduledMeetingState): ScheduledMeeting[] {
            return state.historyScheduledMeetings ? state.historyScheduledMeetings['hydra:member'] : [];
        },
        utmSourceScheduledMeetingsOptions(state: ScheduledMeetingState): ScheduledMeetingFilter[] {
            return state.utmSourceScheduledMeetingsOptions ? state.utmSourceScheduledMeetingsOptions['hydra:member'] : [];
        },
        utmMediumScheduledMeetingsOptions(state: ScheduledMeetingState): ScheduledMeetingFilter[] {
            return state.utmMediumScheduledMeetingsOptions ? state.utmMediumScheduledMeetingsOptions['hydra:member'] : [];
        },
        utmCampaignScheduledMeetingsOptions(state: ScheduledMeetingState): ScheduledMeetingFilter[] {
            return state.utmCampaignScheduledMeetingsOptions ? state.utmCampaignScheduledMeetingsOptions['hydra:member'] : [];
        },
    },
});
