import { cloneDeep, forEach, isNil } from 'lodash';

export const removeEmptyKeys = (item: { [K: string]: any }): { [K: string]: any } => {
    const result = cloneDeep(item);
    forEach(result, (value, key) => {
        if (isNil(value) || value === '') delete result[key];
    });

    return result;
};
