// organize-imports-ignore
import { createApp } from 'vue';
import { IonicVue } from '@ionic/vue';
import { VueClipboard } from '@soerenmartius/vue3-clipboard';
import i18n from '@/i18n';
import router from '@/router';
import store from '@/store';
import { initSentry } from '@/sentry';
import '@/yup';
import VueTheMask from 'vue-the-mask';
import App from './App.vue';

import '@/theme/styles.scss';
import '@/theme/variables.scss';

import '@/bootstrap';

const app = createApp(App)
    .use(IonicVue, {
        mode: 'ios',
        innerHTMLTemplatesEnabled: true,
    })
    .use(VueClipboard)
    .use(VueTheMask)
    .use(store)
    .use(i18n)
    .use(router);

initSentry(app);

router.isReady().then(() => {
    app.mount('#app');
});
