import { getBaseStore } from '@/store/base';
import { merge } from 'lodash';

export interface ClosingPeriodState {
    closingPeriod: ClosingPeriod | null;
    closingPeriods: ClosingPeriods | null;
    closingPeriodOptions: ClosingPeriods | null;
}

export const closingPeriod = merge(getBaseStore('closingPeriod'));
