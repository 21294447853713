<template>
    <ion-app :class="{ 'menu-open': menuIsOpen }">
        <router-view v-slot="{ Component }">
            <transition name="page" mode="out-in">
                <component :is="Component" />
            </transition>
        </router-view>
    </ion-app>
    <teleport to="body">
        <transition name="backdrop">
            <div v-if="backdropVisible">
                <ion-backdrop
                    :style="(darkBackdrop ? 'opacity: 0.1' : 'opacity: 0') + '; z-index: 29995'"
                    @ionBackdropTap="onBackdropTap"
                />
            </div>
        </transition>
        <div v-if="showContainer" ref="optionsPanelRef" style="z-index: 30000" :class="className">
            <transition :name="transitionName">
                <div class="dropdown-container" v-if="isOpen">
                    <component v-bind="activeProps" :is="activeCmp" @on-update="onUpdate" />
                </div>
            </transition>
        </div>
    </teleport>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'App',
    });
</script>

<script setup lang="ts">
    import { backdropVisible, darkBackdrop, onBackdropTap } from '@/composables/backdrop';
    import Dropdown from '@/composables/dropdown';
    import { App, URLOpenListenerEvent } from '@capacitor/app';
    import { IonApp, IonBackdrop, useBackButton } from '@ionic/vue';
    import { computed } from 'vue';
    import { useRouter } from 'vue-router';
    import { useStore } from 'vuex';
    import { interactionsPool } from '@/composables/interaction';

    const store = useStore();
    const router = useRouter();
    const { activeCmp, activeProps, isOpen, showContainer, optionsPanelRef, onUpdate, className, transitionName } = Dropdown;

    const menuIsOpen = computed(() => store.state.navigation.isOpen);

    useBackButton(-1, () => {
        if (window.history.length > 0) {
            router.back();
        } else {
            App.exitApp();
        }
    });

    App.addListener('appUrlOpen', function (event: URLOpenListenerEvent) {
        const url = new URL(event.url);
        router.push(url.pathname + url.search);
    });
</script>
