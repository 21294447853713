import Axios, { activeAbortControllers } from '@/axios';
import { GetItemsPayload, RootState } from '@/store/index';
import { generateId } from '@/utils/generateId';
import { getPlural } from '@/utils/getPlural';
import { camelCase, snakeCase, upperFirst } from 'lodash';
import { ActionContext } from 'vuex';

export const getItemsAction = async (
    { state, commit }: ActionContext<object, RootState>,
    { params, url, options = false, prefixName = '', callId }: GetItemsPayload,
    entityName: string,
    prePath = '',
) => {
    const entityStateName = options ? upperFirst(entityName) + 'Options' : upperFirst(getPlural(entityName));
    const stateName = camelCase(upperFirst(prefixName) + entityStateName);
    const commitName = camelCase('update' + upperFirst(stateName));
    const controller = new AbortController();
    const id = callId ?? generateId();
    activeAbortControllers[id] = controller;
    const data = await Axios.request({
        method: 'get',
        url: url ?? '/api/private/' + prePath + snakeCase(getPlural(entityName)),
        signal: controller.signal,
        params: {
            ...(options && {
                pagination: false,
                'extra[permissions]': 0,
                'extra[front_actions]': 0,
            }),
            ...params,
        },
    });
    if (activeAbortControllers[id]) delete activeAbortControllers[id];

    commit(commitName, data);
    return data;
};
