import { RootState } from '@/store';
import { getBaseStore } from '@/store/base';
import { merge } from 'lodash';
import { ActionContext } from 'vuex';

export interface PageState {
    page: Page | null;
    pages: Pages | null;
    offerPages: Pages | null;
}

export const page = merge(getBaseStore('page'), {
    actions: {
        async createPage({ commit, state }: ActionContext<PageState, RootState>) {
            commit('updatePage', {
                title: '',
                content: '',
                access_level: [],
            });

            return state.page;
        },
    },
});
