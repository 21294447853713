import { RootState } from '@/store';
import { getBaseStore } from '@/store/base';
import { merge } from 'lodash';
import { ActionContext } from 'vuex';

export interface DataSourceState {
    dataSource: DataSource | null;
    dataSources: DataSources | null;
    dataSourceOptions: DataSources | null;
}

export const dataSource = merge(getBaseStore('dataSource'), {
    actions: {
        async createDataSource({ commit, state }: ActionContext<DataSourceState, RootState>) {
            commit('updateDataSource', {
                label: '',
            });
            return state.dataSource;
        },
    },
});
