import { RootState } from '@/store';
import { getBaseStore } from '@/store/base';
import { merge } from 'lodash';
import { ActionContext } from 'vuex';

export interface AreaState {
    area: Area | null;
    areas: Areas | null;
    areaOptions: Areas | null;
}

export const area = merge(getBaseStore('area'), {
    actions: {
        async createArea({ commit, state }: ActionContext<AreaState, RootState>) {
            commit('updateArea', {
                enabled: false,
                name: '',
                default_timezone: 'Europe/Paris',
                allow_timezone_change: false,
            });
            return state.area;
        },
    },
});
