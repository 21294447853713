import i18n from '@/i18n';
import { RootState } from '@/store';
import { getBaseStore } from '@/store/base';
import { merge } from 'lodash';
import { ActionContext } from 'vuex';

export interface InviteState {
    invite: Invite | null;
    invites: Invites | null;
    inviteOptions: Invites | null;
}

export const invite = merge(getBaseStore('invite'), {
    actions: {
        async createInvite({ commit, state }: ActionContext<InviteState, RootState>) {
            commit('updateInvite', {
                proposed_date_times: [],
                field_values: {},
                subject: i18n.global.t('invite.entity.subjectPlaceholder'),
                message: i18n.global.t('invite.entity.messagePlaceholder'),
            });

            return state.invite;
        },
    },
});
