import Store from '@/store';
import { onMounted, onUnmounted } from 'vue';

const DELAY_FETCHING = 50000;
export const fetchingNotifications = async () => await Store.dispatch('appEvent/getUnreadAppEvents');
let fetchInterval = null;
const clearFetchInterval = () => clearInterval(fetchInterval);

export const stopFetchingInterval = () => {
    clearFetchInterval();
};

export const startFetchingInterval = () => {
    fetchInterval = setInterval(fetchingNotifications, DELAY_FETCHING);
};

export const setupNotifsCenter = () => {
    onMounted(() => {
        fetchingNotifications();
        startFetchingInterval();
    });

    onUnmounted(() => {
        stopFetchingInterval();
    });
};
