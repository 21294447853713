import { RootState } from '@/store';
import { getBaseStore } from '@/store/base';
import { merge } from 'lodash';
import { ActionContext } from 'vuex';

export interface HubState {
    hub: Hub | null;
    hubs: Hubs | null;
    hubOptions: Hubs | null;
}

export const hub = merge(getBaseStore('hub'), {
    actions: {
        async createHub({ commit, state }: ActionContext<HubState, RootState>) {
            commit('updateHub', {
                name: '',
                groups: [],
                choices: [],
            });

            return state.hub;
        },
    },
});
