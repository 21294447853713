import NotifsModal from '@/components/notification/NotifsModal.vue';
import { Capacitor } from '@capacitor/core';
import { modalController } from '@ionic/vue';

import {
    addListenersNative,
    registerNotificationsNative,
    updateNotificationPermissionNative,
} from '@/composables/push-notifications-native';

import { addListenersWeb, registerNotificationsWeb, updateNotificationPermissionWeb } from '@/composables/push-notifications-web';

let listenersActive = false;

export const addListeners = async () => {
    if (listenersActive) return;

    if (Capacitor.isNativePlatform()) {
        await addListenersNative();
    } else {
        await addListenersWeb();
    }

    listenersActive = true;
};

export const registerNotifications = async () => {
    if (Capacitor.isNativePlatform()) {
        await registerNotificationsNative();
    } else {
        await registerNotificationsWeb();
    }

    updateNotificationPermission();
};

export const updateNotificationPermission = async () => {
    if (Capacitor.isNativePlatform()) {
        await updateNotificationPermissionNative();
    } else {
        await updateNotificationPermissionWeb();
    }
};

export const showNotificationModal = async () => {
    const modal = await modalController.create({
        component: NotifsModal,
        backdropDismiss: false,
    });
    await modal.present();
};

export const closeNotificationModal = async () => {
    await modalController.dismiss();
};
