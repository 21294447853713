import { addListeners } from '@/composables/push-notifications';
import { toast } from '@/composables/toast';
import { app } from '@/firebase';
import Store from '@/store';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

let messaging = null;

const sendTokenToApenday = async () => {
    try {
        const token = await getToken(messaging, {
            vapidKey: 'BC2dUh7C0jXdTiW6gZxjQe8zE1mhGiebtxSLp7rAtu5P--BWQt8Ypnnp03c7Mv03AMLAfRCJpdZYbK1-pIHi-gg',
        });
        await Store.dispatch('user/addDeviceToken', { token });
    } catch (e) {
        toast({
            text: e.customErrorMsg ?? e.toString(),
            type: 'error',
        });
    }
};

export const addListenersWeb = async () => {
    onMessage(messaging, (payload) => {
        console.log('foreground');
        console.log('Message received. ', payload);
    });
};

export const registerNotificationsWeb = async () => {
    console.log('permission : ', Notification.permission);

    if (Notification.permission === 'default') {
        await Notification.requestPermission();
    }

    if (Notification.permission !== 'granted') {
        throw new Error('User denied permissions!');
    }
};

export const updateNotificationPermissionWeb = async () => {
    if (Notification.permission === 'granted') {
        if (!messaging) messaging = getMessaging(app);
        await addListeners();
        await sendTokenToApenday();
    }
};
