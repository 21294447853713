import responsive from '@/composables/responsive';
import { emitter } from '@/events';
import { ref, watch } from 'vue';

export const backdropVisible = ref(false);
export const darkBackdrop = ref(false);
export const onBackdropTap = () => {
    backdropVisible.value = false;
    emitter.emit('backdropTap');
};

export const backdrop = (dark = true) => {
    const isOpen = ref(false);
    const { isTablet } = responsive;

    watch(isOpen, (val) => {
        darkBackdrop.value = isTablet.value ? true : dark;
        backdropVisible.value = val;
        if (val) {
            emitter.on('backdropTap', () => {
                isOpen.value = false;
                emitter.off('backdropTap');
            });
        } else {
            emitter.off('backdropTap');
        }
    });

    return {
        isOpen,
    };
};
