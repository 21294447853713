import { getLastValue } from '@/utils/getLastValue';
import { localize, setLocale } from '@vee-validate/i18n';
import {
    alpha,
    alpha_dash,
    alpha_num,
    alpha_spaces,
    between,
    digits,
    integer,
    length,
    max,
    max_value,
    min,
    min_value,
    numeric,
    regex,
    required,
} from '@vee-validate/rules';
import { configure, defineRule } from 'vee-validate';

defineRule('required', (value, records, { field }) => {
    if (field === 'datasource_multiselect') {
        if (!value || typeof value !== 'string') return false;
        const _value = JSON.parse(value) as Record<string | '__other__', DatasourceValue>;
        if ('__other__' in _value) {
            return required(_value['__other__']);
        }
        const lastValue = getLastValue<DatasourceValue>(_value);

        return !lastValue['has_child'];
    } else {
        return required(value);
    }
});
defineRule('email', (value) => {
    // Custom rule because vendor test fail when value is empty :/

    // Field is empty, should pass
    if (!value || !value.length) {
        return true;
    }

    // Check if email
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (Array.isArray(value)) {
        return value.every((val) => re.test(String(val)));
    }
    return re.test(String(value));
});
defineRule('numeric', numeric);
defineRule('length', length);
defineRule('alpha', alpha);
defineRule('alpha_dash', alpha_dash);
defineRule('alpha_num', alpha_num);
defineRule('alpha_spaces', alpha_spaces);
defineRule('between', between);
defineRule('digits', digits);
defineRule('integer', integer);
defineRule('max', max);
defineRule('max_value', max_value);
defineRule('min', min);
defineRule('min_value', min_value);
defineRule('regex', regex);

configure({
    generateMessage: localize('fr', {
        messages: {
            alpha: 'Ce champ ne peut contenir que des lettres',
            alpha_num: 'Ce champ ne peut contenir que des caractères alpha-numériques',
            alpha_dash: 'Ce champ ne peut contenir que des caractères alpha-numériques, tirets ou soulignés',
            alpha_spaces: 'Ce champ ne peut contenir que des lettres ou des espaces',
            between: 'Ce champ doit être compris entre 0:{min} et 1:{max}',
            confirmed: 'Ce champ ne correspond pas',
            digits: 'Ce champ doit être un nombre entier de 0:{length} chiffres',
            dimensions: 'Ce champ doit avoir une taille de 0:{width} pixels par 1:{height} pixels',
            email: 'Ce champ doit être une adresse e-mail valide',
            excluded: 'Ce champ doit être une valeur valide',
            ext: 'Ce champ doit être un fichier valide',
            image: 'Ce champ doit être une image',
            integer: 'Ce champ doit être un entier',
            length: 'Ce champ doit contenir 0:{length} caractères',
            max_value: 'Ce champ doit avoir une valeur de 0:{max} ou moins',
            max: 'Ce champ ne peut pas contenir plus de 0:{length} caractères',
            mimes: 'Ce champ doit avoir un type MIME valide',
            min_value: 'Ce champ doit avoir une valeur de 0:{min} ou plus',
            min: 'Ce champ doit contenir au minimum 0:{length} caractères',
            numeric: 'Ce champ ne peut contenir que des chiffres',
            oneOf: 'Ce champ doit être une valeur valide',
            regex: 'Ce champ est invalide',
            required: 'Ce champ est obligatoire',
            required_if: 'Ce champ est obligatoire lorsque {target} possède cette valeur',
            size: 'Ce champ doit avoir un poids inférieur à 0:{size}KB',
        },
    }),
});

setLocale('fr');
