import { RootState } from '@/store';
import { getBaseStore } from '@/store/base';
import { merge } from 'lodash';
import { ActionContext } from 'vuex';

export interface FtpExportState {
    ftpExport: FtpExport | null;
    ftpExports: FtpExports | null;
    ftpExportOptions: FtpExports | null;
}

export const ftpExport = merge(getBaseStore('ftpExport'), {
    actions: {
        async createFtpExport({ commit, state }: ActionContext<FtpExportState, RootState>) {
            commit('updateFtpExport', {});

            return state.ftpExport;
        },
    },
});
