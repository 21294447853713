import Axios from '@/axios';
import { GetItemPayload, RootState } from '@/store';
import { getBaseStore } from '@/store/base';
import { merge } from 'lodash';
import { ActionContext } from 'vuex';

export interface MeetingState {
    meetings: Meetings | null;
    meetingOptions: Meetings | null;
    meeting: Meeting | null;
}

export const meeting = merge(getBaseStore('meeting'), {
    actions: {
        async duplicateMeeting(context: ActionContext<MeetingState, RootState>, id: string) {
            return await Axios.request({
                method: 'post',
                url: '/api/private/meetings/' + id + '/copy',
                data: {},
            });
        },
        async createMeeting({ commit, state }: ActionContext<MeetingState, RootState>, { routeLocation }: GetItemPayload) {
            const isTeam = routeLocation.meta.meetingMode === 'team';
            const isGroup = routeLocation.meta.meetingMode === 'group';

            const meeting: Meeting = {
                capacity: isGroup ? 2 : 1,
                min_planification_delay: 0,
                min_delay_between: 0,
                min_planification_delay_unit: 'min',
                min_update_delay_unit: 'min',
                min_cancellation_delay_unit: 'min',
                min_delay_between_unit: 'min',
                limited_schedules_period_unit: 'min',
                show_week_ends: true,
                agency_selection_enabled: !isTeam,
                allowed_agency_filtering: !isTeam,
                enabled_agency_geolocation: !isTeam,
                max_schedule_per_day: 0,
                permissions: {
                    change_groups: true,
                },
                consultant_selection_policy: isTeam ? 'by_team' : 'by_user',
                mode: routeLocation.meta.meetingMode,
                allowed_tags: [],
            };

            commit('updateMeeting', meeting);

            return state.meeting;
        },
    },
});
