import { addListeners } from '@/composables/push-notifications';
import { toast } from '@/composables/toast';
import Store from '@/store';
import { PushNotifications } from '@capacitor/push-notifications';

export const addListenersNative = async () => {
    await PushNotifications.addListener('registration', async (token) => {
        console.info('Registration token: ', token.value);
        try {
            await Store.dispatch('user/addDeviceToken', { token: token.value });
        } catch (e) {
            toast({
                text: e.customErrorMsg ?? e.toString(),
                type: 'error',
            });
        }
    });

    await PushNotifications.addListener('registrationError', (err) => {
        console.error('Registration error: ', err.error);
    });

    await PushNotifications.addListener('pushNotificationReceived', (notification) => {
        console.log('Push notification received: ', notification);
    });

    await PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
        console.log('Push notification action performed', notification.actionId, notification.inputValue);
    });
};

export const registerNotificationsNative = async () => {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
        throw new Error('User denied permissions!');
    }
};

export const updateNotificationPermissionNative = async () => {
    const permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'granted') {
        await addListeners();
        await PushNotifications.register();
    }
};
