// Import the functions you need from the SDKs you need
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
// Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyCh0nvrRph-R6yK0Qja28NeM80-DEpE5Rg',
    authDomain: 'apenday-admin.firebaseapp.com',
    projectId: 'apenday-admin',
    storageBucket: 'apenday-admin.appspot.com',
    messagingSenderId: '968666727698',
    appId: '1:968666727698:web:849fa3f06cdee306245fcb',
    measurementId: 'G-68R3LT7QPL',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
