import { RootState } from '@/store';
import { getBaseStore } from '@/store/base';
import { merge } from 'lodash';
import { ActionContext } from 'vuex';

export interface SmsSettingState {
    smsSetting: SmsSetting | null;
    smsSettings: SmsSettings | null;
    smsSettingOptions: SmsSettings | null;
}

export const smsSetting = merge(getBaseStore('smsSetting'), {
    actions: {
        async createSmsSetting({ commit, state }: ActionContext<SmsSettingState, RootState>) {
            commit('updateSmsSetting', {
                name: '',
            });

            return state.smsSetting;
        },
    },
});
