import Dexie, { Table } from 'dexie';

export class MySubClassedDexie extends Dexie {
    constants!: Table<any>;

    constructor() {
        super('apenday-admin');

        this.version(1).stores({
            constants: '',
        });
    }
}

export const db = new MySubClassedDexie();
