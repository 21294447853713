import { RootState } from '@/store';
import { getBaseStore } from '@/store/base';
import { merge } from 'lodash';
import { ActionContext } from 'vuex';

export interface ConstraintState {
    constraint: Constraint | null;
    constraints: Constraints | null;
    constraintOptions: Constraints | null;
}

export const constraint = merge(getBaseStore('constraint'), {
    actions: {
        async createConstraint({ commit, state }: ActionContext<ConstraintState, RootState>) {
            commit('updateConstraint', {
                name: '',
            });

            return state.constraint;
        },
    },
});
