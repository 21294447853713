import responsive from '@/composables/responsive';
import { bottomSideMenuRoutes, SideMenuRoute, topSideMenuRoutes } from '@/router/sideMenuRoutes';
import { RootState } from '@/store';
import { wait } from '@/utils/wait';
import { concat, find } from 'lodash';
import { Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { RouteLocationNormalized, Router } from 'vue-router';
import { ActionContext } from 'vuex';

export interface NavigationState {
    isOpen: boolean;
    activeTopLvl1: SideMenuRoute | null;
    transition: string;
    showTopMenu: boolean;
}

export const navigation = {
    namespaced: true,
    state: (): NavigationState => ({
        isOpen: false,
        activeTopLvl1: null,
        showTopMenu: true,
        transition: 'menu-slide-next',
    }),
    mutations: {
        toggleMenu: (state: NavigationState, open: boolean) => {
            if (open !== undefined) {
                state.isOpen = open;
            } else {
                state.isOpen = !state.isOpen;
            }
        },
        setActiveTopLvl1: (state: NavigationState, value: SideMenuRoute | null) => {
            state.activeTopLvl1 = value;
        },
        setTransitionValue: (state: NavigationState, value: string) => {
            state.transition = value;
        },
        setShowTopMenu: (state: NavigationState, value: boolean) => {
            state.showTopMenu = value;
        },
    },
    actions: {
        async syncLvlNavigation({ commit }: ActionContext<NavigationState, RootState>, { to }: { to: RouteLocationNormalized }) {
            const { isTablet } = responsive;

            if (
                (to.name?.toString().includes('page-') && !isTablet.value) ||
                (to.name?.toString().includes('support-thread-') && !isTablet.value)
            ) {
                commit('toggleMenu', false);
                return;
            }

            const topLevelMenu: any = find(concat(topSideMenuRoutes, bottomSideMenuRoutes), (o) => {
                if (to.path.includes(o.path)) {
                    return true;
                } else if (o.children?.length) {
                    return find(o.children, (sub) => to.path.includes(sub.path));
                } else {
                    return false;
                }
            });

            if (topLevelMenu?.children?.length) {
                commit('setTransitionValue', 'menu-slide-next');
                commit('setActiveTopLvl1', topLevelMenu);

                if (!isTablet.value) commit('toggleMenu', true);
            } else {
                commit('setTransitionValue', 'menu-slide-prev');
                commit('setActiveTopLvl1', null);
                commit('toggleMenu', false);
            }
        },
        async onClickTopLvl1(
            { commit }: ActionContext<NavigationState, RootState>,
            { route, router }: { route: SideMenuRoute; router: Router },
        ) {
            commit('setTransitionValue', 'menu-slide-next');
            if (route.children) {
                commit('setActiveTopLvl1', route);
                return;
            }
            const { isTablet } = responsive;

            if (isTablet.value) commit('toggleMenu');
            await wait(200); // wait for menu to close
            if (route.path) router.push(route.path);
        },
        async onClickTopLvl2(
            { commit, dispatch, rootState }: ActionContext<NavigationState, RootState>,
            {
                sideMenuRoute,
                route,
                router,
            }: {
                sideMenuRoute: SideMenuRoute;
                route: RouteLocationNormalized;
                router: Router;
            },
        ) {
            const { isTablet } = responsive;
            if (isTablet.value) commit('toggleMenu');
            await wait(200);

            if (sideMenuRoute.jsNavKey) {
                if (sideMenuRoute.jsNavKey === 'logout') {
                    await dispatch('user/logOut', { showLogoutScreen: true }, { root: true });
                }
                if (sideMenuRoute.jsNavKey === 'myProfile') {
                    router.push({
                        name: 'user-edit',
                        params: { id: rootState.user.me.id },
                    });
                }
                if (sideMenuRoute.jsNavKey === 'killImpersonate') {
                    await dispatch('user/killImpersonate', null, { root: true });
                    if (route.name === 'dashboard') {
                        location.reload();
                    } else {
                        router.push({ name: 'dashboard' });
                    }
                }
            } else if (sideMenuRoute.path) router.push(sideMenuRoute.path);
        },
        async onClickBottomLvl1(
            { commit }: ActionContext<NavigationState, RootState>,
            { route, router }: { route: SideMenuRoute; router: Router },
        ) {
            commit('setTransitionValue', 'menu-slide-next');
            commit('setShowTopMenu', false);
            if (route.children) {
                commit('setActiveTopLvl1', route);
                return;
            }
            if (route.path) router.push(route.path);
        },
        goBackRootLvl({ commit, getters }: ActionContext<NavigationState, RootState>) {
            commit('setTransitionValue', 'menu-slide-prev');
            if (getters['topRoutes'].length === 1) {
                commit('setActiveTopLvl1', getters['topRoutes'][0]);
            } else {
                commit('setActiveTopLvl1', null);
            }
            commit('setShowTopMenu', true);
        },
    },
    getters: {
        lvl2routes(state: NavigationState) {
            return state.activeTopLvl1 && state.activeTopLvl1.children && state.activeTopLvl1.children.length
                ? state.activeTopLvl1.children
                : null;
        },
        activeTitle: (state: NavigationState) => (route: RouteLocationNormalized, me: Ref<User>) => {
            const { t } = useI18n();
            if (state.activeTopLvl1?.jsNavKey === 'user') return me?.value?.['full_name'];
            if (state.activeTopLvl1) {
                return t(state.activeTopLvl1.key);
            }
            const item = find(topSideMenuRoutes, (o) => o.path === route.path);
            if (item) {
                return t(item.key);
            }
            return 'Apenday';
        },
        topRoutes(state, getters, rootState, rootGetters) {
            return topSideMenuRoutes.filter((route) => (route.isGranted ? rootGetters['user/isGranted'][route.isGranted] : true));
        },
        bottomRoutes(state, getters, rootState, rootGetters) {
            return bottomSideMenuRoutes.filter((route) => (route.isGranted ? rootGetters['user/isGranted'][route.isGranted] : true));
        },
        showBackBtn(state, getters) {
            return !state.showTopMenu || getters['topRoutes'].length > 1;
        },
    },
};
