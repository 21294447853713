import Axios from '@/axios';
import { RootState } from '@/store';
import { getBaseStore } from '@/store/base';
import { merge } from 'lodash';
import { ActionContext } from 'vuex';

export interface ExternalCrmFieldsState {
    externalCrmFields: ExternalCrmField[] | null;
}

export const externalCrmField = merge(getBaseStore('externalCrmField'), {
    actions: {
        async getExternalCrmFields({ commit, state }: ActionContext<ExternalCrmFieldsState, RootState>, { params }) {
            const data: {
                crm_fields: ExternalCrmField[];
            } = await Axios.request({
                method: 'get',
                url: `/api/private/areas/${params.id}/type/${params.crmProvider}`,
            });
            commit('updateExternalCrmFields', data.crm_fields);

            return data.crm_fields;
        },
    },
    getters: {
        externalCrmFields(state): ExternalCrmField[] {
            return state['externalCrmFields'] ? state['externalCrmFields'] : [];
        },
    },
});
