import Store from '@/store';

export interface SideMenuRoute {
    key?: string;
    path?: string;
    icon?: string;
    children?: SideMenuRoute[];
    isGranted?: string;
    hideFor?: UserLevel[];
    hiddenOnDesktop?: boolean;
    hiddenOnMobile?: boolean;
    jsNavKey?: string;
    show?: () => boolean;
}

export const topSideMenuRoutes: SideMenuRoute[] = [
    {
        key: 'menu.assist.assist',
        path: '/main/session',
        icon: 'assist',
        show: () => {
            return Store.getters['user/hasApendayAssistEnabled'] || Store.getters['user/isGranted']['admin'];
        },
        children: [
            {
                key: 'menu.session.dashboard',
                path: '/main/session/dashboard',
                show: () => {
                    return !(Store.getters['user/isGranted']['admin'] && !Store.state.user.me?.twilio_tokens?.length);
                },
            },
            {
                key: 'menu.session.inProgress',
                path: '/main/session/in-progress',
            },
            {
                key: 'menu.session.closed',
                path: '/main/session/closed',
            },
        ],
    },
    {
        key: 'menu.live.live',
        path: '/main/live',
        icon: 'video-menu',
        show: () => {
            return Store.getters['user/hasApendayLiveEnabled'] || Store.getters['user/isGranted']['admin'];
        },
        children: [
            {
                key: 'menu.interaction.dashboard',
                path: '/main/live/dashboard',
                show: () => {
                    return !(Store.getters['user/isGranted']['admin'] && !Store.state.user.me?.twilio_tokens?.length);
                },
            },
            {
                key: 'menu.widget.list',
                path: '/main/live/widget/list',
            },
            {
                key: 'menu.interaction.pending',
                path: '/main/live/interaction/pending',
            },
            {
                key: 'menu.interaction.inProgress',
                path: '/main/live/interaction/in-progress',
            },
            {
                key: 'menu.interaction.closed',
                path: '/main/live/interaction/closed',
            },
        ],
    },
    {
        key: 'menu.meeting.dashboard',
        path: '/main/meeting',
        icon: 'calendar',
        show: () => {
            return Store.getters['user/hasApendayBookEnabled'] || Store.getters['user/isGranted']['admin'];
        },
        children: [
            {
                key: 'menu.meeting.hub',
                path: '/main/meeting/hub/list',
                isGranted: 'group_manager',
            },
            {
                key: 'menu.meeting.list',
                path: '/main/meeting/list',
            },
            {
                key: 'menu.meeting.scheduledMeeting.forthcoming',
                path: '/main/meeting/scheduled-meeting/forthcoming',
            },
            {
                key: 'menu.meeting.scheduledMeeting.past',
                path: '/main/meeting/scheduled-meeting/past',
            },
            {
                key: 'menu.meeting.scheduledMeeting.history',
                path: '/main/meeting/scheduled-meeting/history',
            },
        ],
    },
    {
        key: 'menu.config.config',
        path: '/main/config',
        icon: 'settings',
        isGranted: 'manager',
        children: [
            {
                key: 'menu.config.offer',
                path: '/main/config/offer/list',
                isGranted: 'admin',
            },
            {
                key: 'menu.config.area',
                path: '/main/config/area/list',
                isGranted: 'manager',
            },
            {
                key: 'menu.config.globalConfig',
                path: '/main/config/globalConfig/list',
                isGranted: 'admin',
            },
            {
                key: 'menu.config.constraints',
                path: '/main/config/constraint/list',
                isGranted: 'admin',
            },
            {
                key: 'menu.config.dataSource',
                path: '/main/config/data-source/list',
                isGranted: 'group_manager',
            },
        ],
    },
    {
        key: 'menu.settings.settings',
        path: '/main/settings',
        icon: 'sliders',
        isGranted: 'group_manager',
        children: [
            {
                key: 'menu.settings.user',
                path: '/main/settings/user/list',
            },
            {
                key: 'menu.settings.group',
                path: '/main/settings/group/list',
                isGranted: 'manager',
            },
            {
                key: 'menu.settings.agency',
                path: '/main/settings/agency/list',
            },
            {
                key: 'menu.settings.userCategory',
                path: '/main/settings/user-category/list',
                isGranted: 'admin',
            },
            {
                key: 'menu.settings.scheduledMeetingTag',
                path: '/main/settings/scheduled-meeting-tag/list',
                isGranted: 'manager',
            },
        ],
    },
    {
        key: 'menu.reporting.reporting',
        path: '/main/reporting',
        icon: 'bar-chart-2',
        isGranted: 'group_manager',
        children: [
            {
                key: 'menu.reporting.book',
                path: '/main/reporting/book/meeting',
                show: () => {
                    return Store.getters['user/hasApendayBookEnabled'];
                },
            },
            {
                key: 'menu.reporting.live',
                path: '/main/reporting/live/interaction',
                show: () => {
                    return Store.getters['user/hasApendayLiveEnabled'];
                },
            },
            {
                key: 'menu.reporting.assist',
                path: '/main/reporting/assist/session',
                show: () => {
                    return Store.getters['user/hasApendayAssistEnabled'];
                },
            },
        ],
    },

    /*  {
key: 'menu.notifications',
path: '/main/settings/notifications',
icon: 'bell',
hiddenOnDesktop: true
},*/
];

export const bottomSideMenuRoutes: SideMenuRoute[] = [
    {
        key: 'menu.help.title',
        icon: 'calendar',
        hiddenOnDesktop: true,
        children: [
            {
                key: 'menu.help.tutorials',
                path: '/main/page/list',
            },
            {
                key: 'menu.help.assistance',
                path: '/main/support-thread/list',
            },
        ],
    },
    {
        hiddenOnDesktop: true,
        jsNavKey: 'user',
        children: [
            {
                key: 'menu.user.myProfile',
                jsNavKey: 'myProfile',
            },
            {
                key: 'menu.user.killImpersonate',
                jsNavKey: 'killImpersonate',
                icon: 'ninja',
            },
            {
                key: 'menu.user.logOut',
                jsNavKey: 'logout',
            },
        ],
    },
];
