// @ts-nocheck
import { activeCommitHash } from '@/env/active-commit-hash';
import { getEnv } from '@/env/env';
import { emitter } from '@/events';
import i18n from '@/i18n';
import Store from '@/store';
import { storageGet, storageSet } from '@/store/modules/user';
import { onImportError } from '@/utils/onImportError';
import { removeEmptyKeys } from '@/utils/removeEmptyKeys';
import { wait } from '@/utils/wait';
import { Capacitor } from '@capacitor/core';
import { Network } from '@capacitor/network';
import Axios from 'axios';
import { forEach, isArray } from 'lodash';

let refreshTokenPending = false;

const axios = Axios.create({
    baseURL: getEnv().baseUrl,
    timeout: 40000,
    headers: {
        // 'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/ld+json',
        Accept: '*/*',
        'X-Api-Context': 'backend',
    },
});

export let activeAbortControllers: { [key in string]: AbortController } = {};

export const cancelAllRequests = () => {
    forEach(activeAbortControllers, (c) => c.abort());
    activeAbortControllers = {};
};

export const cancelRequest = (id: string) => {
    if (activeAbortControllers[id]) {
        activeAbortControllers[id].abort();
        delete activeAbortControllers[id];
    }
};

//********************//
//***** Requests *****//
//********************//

axios.interceptors.request.use(
    async function (config) {
        if (!config.headers) return config;

        if (Store.state.user.impersonateToken) {
            config.headers['Authorization'] = `Bearer ${Store.state.user.impersonateToken}`;
        } else if (Store.state.user.accessToken) {
            config.headers['Authorization'] = `Bearer ${Store.state.user.accessToken}`;
        }
        config.headers['Accept-Language'] = i18n.global.locale.value;
        if (config.method.toLowerCase() === 'patch') config.headers['Content-Type'] = 'application/merge-patch+json';

        if (config['params']) config['params'] = removeEmptyKeys(config['params']);

        return config;
    },
    function (error) {
        return Promise.reject(error);
    },
);

//*********************//
//***** Responses *****//
//*********************//

axios.interceptors.response.use(async (response) => {
    if (!import.meta.env.PROD) return response;
    const apiHash = response?.['headers']?.['x-admin-build-id'];
    const isGetMethod = response?.['config']?.['method'] === 'get';
    if (apiHash && isGetMethod) {
        if (activeCommitHash !== apiHash) {
            const already = await storageGet('updateAlerted');
            if (already === 'true' || already === true) return response;
            storageSet('updateAlerted', true);
            if (Capacitor.isNativePlatform()) {
                // await appUpdateAlert(); // TODO enable when CI App Store deploy is implemented
                return response;
            } else {
                await onImportError();
                await wait(6000); // wait for the app to reload
                return Promise.reject(response);
            }
        } else {
            storageSet('updateAlerted', false);
        }
    }
    return response;
});

// Formating Errors
axios.interceptors.response.use(
    (response) => {
        if (response['headers']['content-type']?.includes('text/html'))
            return Promise.reject({
                response: { status: 666 },
                customErrorMsg: i18n.global.t('common.errorServer'),
                ...response,
            });
        return response.data;
    },
    async (intercept) => {
        if (intercept.response?.data?.violations && isArray(intercept.response?.data?.violations)) {
            intercept.errorsBag = {};
            intercept.response.data.violations.forEach((error) => {
                intercept.errorsBag[error.propertyPath] = error.message;
            });
        }

        if (intercept.response?.data?.message) {
            intercept.customErrorMsg = intercept.response.data.message;
        } else if (intercept.response?.data && intercept.response.data['hydra:description']) {
            intercept.customErrorMsg = intercept.response.data['hydra:description'].replace(/\n/g, '<br>');
        } else if (intercept.response?.data?.error) {
            intercept.customErrorMsg = intercept.response.data.error.message;
        } else if (intercept.message === 'canceled') {
            intercept.customErrorMsg = intercept.message;
            intercept.requestCanceled = true;
        } else {
            const { connected } = await Network.getStatus();
            intercept.customErrorMsg = connected ? i18n.global.t('common.errorServer') : i18n.global.t('common.networkError');
        }

        return Promise.reject(intercept);
    },
);

// Refresh Token
axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        // if unauthorized, get access token
        if (error.config?.['url'] === '/security/token/refresh' || error.config?.['url'] === '/security/authentication_token') {
            return Promise.reject(error);
        }

        if (refreshTokenPending && error?.response?.status === 401) {
            try {
                await new Promise((resolve, reject) => {
                    emitter.on('newRefreshTokenOk', () => {
                        emitter.off('newRefreshTokenOk');
                        emitter.off('newRefreshTokenFail');
                        resolve();
                    });
                    emitter.on('newRefreshTokenFail', () => {
                        emitter.off('newRefreshTokenOk');
                        emitter.off('newRefreshTokenFail');
                        reject();
                    });
                });
                return axios(error.config);
            } catch (e) {
                return Promise.reject(error);
            }
        }

        let ok = false;
        if (error?.response?.status === 401) {
            refreshTokenPending = true;
            for (let index = 2; index > 0 && !ok; index--) {
                ok = await Store.dispatch('user/getRefreshToken', index);
                if (ok) {
                    refreshTokenPending = false;
                    emitter.emit('newRefreshTokenOk');
                    return axios(error.config);
                }
                await wait(300); // wait 300ms before retrying
            }
            refreshTokenPending = false;
            emitter.emit('newRefreshTokenFail');
        }

        return Promise.reject(error);
    },
);

export default axios;
