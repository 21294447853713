import { debounce } from 'lodash';
import { ref } from 'vue';

const loader = document.querySelector('#page-loader');
const startLoader = ref(false);

const start = () => {
    startLoader.value = true;
    startShow();
};

const startShow = debounce(() => {
    if (loader && startLoader.value) {
        loader.classList.add('active');
    }
}, 200);

const finish = () => {
    startLoader.value = false;
    if (loader) loader.classList.remove('active');
};

export default {
    start,
    finish,
};
