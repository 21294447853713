import { darkBackdrop as backdropDark, backdropVisible } from '@/composables/backdrop';
import { emitter } from '@/events';
import { Modifier, createPopper } from '@popperjs/core';
import { Placement } from '@popperjs/core/lib/enums';
import { Component, nextTick, ref, shallowRef, watch } from 'vue';

const optionsPanelRef = ref<HTMLDivElement>(null);
const activeCmp = shallowRef<Component>(null);
const activeProps = ref<object>(null);
const isOpen = ref(false);
const showContainer = ref(false);
const className = ref('');
const transitionName = ref('');
let resolvePromise = null;

watch(isOpen, (val) => {
    backdropVisible.value = val;
    if (!val)
        setTimeout(() => {
            showContainer.value = false;
        }, 300);
});

export const present = async ({
    targetEl,
    component,
    darkBackdrop,
    props,
    placement = 'bottom-end',
    modifiers = [],
    className: classNameInput = '',
    transitionName: transitionNameInput = 'popper',
}: {
    targetEl: HTMLDivElement;
    component: Component;
    darkBackdrop?: boolean;
    props?: object;
    placement?: Placement;
    modifiers?: Array<Partial<Modifier<any, any>>>;
    className?: string;
    transitionName?: string;
}) => {
    activeCmp.value = component;
    activeProps.value = props;
    backdropDark.value = darkBackdrop ?? true;
    showContainer.value = true;
    className.value = classNameInput;
    transitionName.value = transitionNameInput;

    nextTick(() => {
        const popperInstance = createPopper(targetEl, optionsPanelRef.value, {
            placement,
            modifiers,
        });
        isOpen.value = true;

        nextTick(() => {
            popperInstance.update();
        });
        emitter.on('backdropTap', closeDropdown);
    });

    return new Promise<DropdownDataResponse>((resolve) => {
        resolvePromise = resolve;
    });
};

const onUpdate = (data, $event = {}) => {
    isOpen.value = false;
    resolvePromise({
        data,
        $event,
        role: 'confirm',
    });
};

export const closeDropdown = async () => {
    isOpen.value = false;
    emitter.off('backdropTap');
    if (resolvePromise)
        resolvePromise({
            data: null,
            role: 'cancel',
        });
};

export default {
    activeCmp,
    activeProps,
    isOpen,
    optionsPanelRef,
    onUpdate,
    showContainer,
    className,
    transitionName,
};

export interface DropdownDataResponse {
    data: any;
    $event: any;
    role: string;
}
