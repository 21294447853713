import { RootState } from '@/store';
import { getBaseStore } from '@/store/base';
import { merge } from 'lodash';
import { ActionContext } from 'vuex';

export interface BrevoApiSettingState {
    brevoApiSetting: BrevoApiSetting | null;
    brevoApiSettings: BrevoApiSettings | null;
    brevoApiSettingOptions: BrevoApiSettings | null;
}

export const brevoApiSetting = merge(getBaseStore('brevoApiSetting'), {
    actions: {
        async createBrevoApiSetting({ commit, state }: ActionContext<BrevoApiSettingState, RootState>) {
            commit('updateBrevoApiSetting', {
                label: '',
            });

            return state.brevoApiSetting;
        },
    },
});
