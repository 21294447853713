import { getBaseStore } from '@/store/base';
import { merge } from 'lodash';

export interface AreaCrmState {
    areaCrm: AreaCrm | null;
    areaCrms: AreaCrms | null;
    areaCrmOptions: AreaCrms | null;
}

export const areaCrm = merge(getBaseStore('areaCrm'));
