import { RootState } from '@/store';
import { getBaseStore } from '@/store/base';
import { cloneDeep, filter, flattenDeep, forEach, groupBy, map, merge } from 'lodash';
import { ActionContext } from 'vuex';

export interface GroupState {
    group: Group | null;
    groups: Groups | null;
    groupOptions: Groups | null;
}

let allItems = [];

export const group = merge(getBaseStore('group'), {
    actions: {
        async createGroup({ commit, state }: ActionContext<GroupState, RootState>) {
            commit('updateGroup', {
                name: '',
            });

            return state.group;
        },
    },
    getters: {
        sortedGroups(state) {
            if (!state['groups']) return null;
            const items = cloneDeep(state['groups']['hydra:member']);
            allItems = cloneDeep(items);
            const withChilds = addChilds(items);
            const parents = groupBy(withChilds, 'parent')['null'];
            const array = flat(parents);
            return {
                ...state['groups'],
                'hydra:member': flattenDeep(array),
            };
        },
    },
});

const addChilds = (items: Groups[], level = -2) => {
    level++;
    forEach(items, (item) => {
        const childs = getChilds(item);
        if (childs.length) {
            item['childs'] = addChilds(childs, level);
        }
        item['level'] = level;
    });

    return items;
};

const getChilds = (item: Groups) => {
    return filter(cloneDeep(allItems), (o) => o['parent'] === item['@id']);
};

const flat = (items: Groups[]) => {
    return map(items, (item) => {
        if (item['childs'] && item['childs'].length) {
            return [item, flat(item['childs'])];
        } else {
            return item;
        }
    });
};
