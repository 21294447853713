// organize-imports-ignore
import responsive from '@/composables/responsive';
import { getEnv } from '@/env/env';
import { StatusBar, Style } from '@capacitor/status-bar';

import './firebase';
import './vee-validate-front';
import { Capacitor } from '@capacitor/core';

/**  Responsive Utils  **/
const { init: initResponsive } = responsive;
initResponsive();

/**  Popin Script  **/
const urlObject = new URL(getEnv().baseUrl);
//@ts-ignore:next-line
window.scheduling = { server: urlObject.host };

const scriptScheduling = document.createElement('script');
scriptScheduling.setAttribute('src', getEnv().baseUrl + '/assets/js/scheduling.js');
scriptScheduling.setAttribute('defer', '');
document.head.appendChild(scriptScheduling);
/** End Popin Script **/

if (Capacitor.isNativePlatform()) {
    StatusBar.setStyle({ style: Style.Light });
    StatusBar.setBackgroundColor({ color: '#ffffff' });
}
