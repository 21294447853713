import { RootState } from '@/store';
import { getBaseStore } from '@/store/base';
import { filter, merge } from 'lodash';
import { ActionContext } from 'vuex';

export interface UserCategoryState {
    userCategory: UserCategory | null;
    userCategories: UserCategories | null;
    userCategoryOptions: UserCategories | null;
}

export const userCategory = merge(getBaseStore('userCategory'), {
    actions: {
        async createUserCategory({ commit, state }: ActionContext<UserCategoryState, RootState>) {
            commit('updateUserCategory', {
                name: '',
            });

            return state.userCategory;
        },
    },
    getters: {
        serviceOptions(state) {
            return state['userCategoryOptions'] ? filter(state['userCategoryOptions']['hydra:member'], (o) => o.type === 'service') : [];
        },
        functionOptions(state) {
            return state['userCategoryOptions'] ? filter(state['userCategoryOptions']['hydra:member'], (o) => o.type === 'function') : [];
        },
    },
});
