import { forEach } from 'lodash';

export function getLastValue<T extends { level: number }>(values: Record<number, T | undefined>): T {
    let keyOfHighestLevel = '0';
    let testLevel = 0;

    forEach(values, (val, key) => {
        if (val?.level > testLevel) {
            testLevel = val.level;
            keyOfHighestLevel = key;
        }
    });

    return values[keyOfHighestLevel];
}
