import { RootState } from '@/store';
import { getBaseStore } from '@/store/base';
import { merge } from 'lodash';
import { ActionContext } from 'vuex';

export interface OfferState {
    offer: Offer | null;
    offers: Offers | null;
    offerOptions: Offers | null;
}

export const offer = merge(getBaseStore('offer'), {
    actions: {
        async createOffer({ commit, state }: ActionContext<OfferState, RootState>) {
            commit('updateOffer', {
                name: '',
            });
            return state.offer;
        },
    },
});
